import PropTypes from "prop-types";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import AspectRatio from "../AspectRatio";
import MarkdownToHtml from "../MarkdownToHtml";
import Image from "../Image";
import LinkTo from "../LinkTo";

const ImageCard = ({ data, wrapperSx, hideJobTitle, imageSx }) => {
  const theme = useContext(ThemeContext);
  const { memberImage, socialMediaLinks, title, memberDesignation } = data;
  const iconStyled = {
    color: "inherit",
    pr: [1, null, null, null],
    pt: [null, null, null, 2],
  };
  return (
    <Box
      sx={{
        p: 4,
        ...wrapperSx,
      }}
    >
      {memberImage && (
        <AspectRatio sx={{ img: { objectFit: "cover", width: "100%" } }}>
          <Image img={memberImage} sx={imageSx} />
          <Box
            sx={{
              "&:before": {
                backgroundImage: theme.baseGradients.primary[0],
                bottom: 0,
                content: "''",
                left: 0,
                opacity: [".45", null, null, "0"],
                position: "absolute",
                right: 0,
                top: 0,
                transition: "opacity .75s",
              },
              "&:hover": {
                "&:before": {
                  opacity: ["0", null, null, ".85"],
                },
                opacity: "1",
              },
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "flex-end",
              left: 0,
              opacity: ["1", null, null, "0"],
              p: 3,
              position: "absolute",
              top: 0,
              transition: "opacity .75s",
              width: "100%",
            }}
          >
            <MarkdownToHtml
              heading={
                hideJobTitle
                  ? `### ${title}`
                  : `### ${title}\r\n\r\n${memberDesignation}`
              }
              sx={{
                color: "white",
                h3: { color: "inherit", mb: 0 },
                p: { mb: 0 },
                position: "relative",
                width: "calc(100% - 2.4rem)",
              }}
            />
            {socialMediaLinks && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  right: 2,
                }}
              >
                {socialMediaLinks.linkedIn && (
                  <LinkTo isLink={socialMediaLinks.linkedIn} target="_blank">
                    <AspectRatio sx={{ color: "inherit", width: "3.1rem" }}>
                      <Image svgIcon="square-linkedin" sx={iconStyled} />
                    </AspectRatio>
                  </LinkTo>
                )}
                {socialMediaLinks.facebook && (
                  <LinkTo isLink={socialMediaLinks.facebook} target="_blank">
                    <AspectRatio sx={{ color: "inherit", width: "3.1rem" }}>
                      <Image svgIcon="square-facebook" sx={iconStyled} />
                    </AspectRatio>
                  </LinkTo>
                )}
                {socialMediaLinks.twitter && (
                  <LinkTo isLink={socialMediaLinks.twitter} target="_blank">
                    <AspectRatio sx={{ color: "inherit", width: "3.1rem" }}>
                      <Image svgIcon="square-twitter" sx={iconStyled} />
                    </AspectRatio>
                  </LinkTo>
                )}
              </Box>
            )}
          </Box>
        </AspectRatio>
      )}
    </Box>
  );
};

ImageCard.propTypes = {
  data: PropTypes.shape({
    memberDesignation: PropTypes.string,
    memberImage: PropTypes.string,
    socialMediaLinks: PropTypes.shape({
      facebook: PropTypes.string,
      linkedIn: PropTypes.string,
      twitter: PropTypes.string,
    }),
    title: PropTypes.string,
  }).isRequired,
  hideJobTitle: PropTypes.bool,
  imageSx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

ImageCard.defaultProps = {
  hideJobTitle: false,
  imageSx: {},
  wrapperSx: {},
};

export default ImageCard;
