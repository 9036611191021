import React from "react";
import { Router } from "@reach/router";
import AboutPage from "../components/AboutPage";
import NotFoundPage from "./404";

const aboutPageRouter = (props) => (
  <Router>
    <AboutPage {...props} path="/about" />
    <NotFoundPage default />
  </Router>
);

export default aboutPageRouter;
