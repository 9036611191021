import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { sortBy } from "lodash";
import AboutPageContainer from "../../containers/AboutPageContainer";
import PageBanner from "../../sections/PageBanner";
import PageIntro from "../../sections/PageIntro";
import PointsColumns from "../../sections/PointsColumns";
import CardsGrid from "../../sections/CardsGrid";
import Testimonials from "../../sections/Testimonials";
import Section from "../Section";
import Box from "../../base/Box";
import MarkdownToHtml from "../MarkdownToHtml";
import ImageCard from "../ImageCard";
import { MEMBER_TYPE } from "../../utilities/constants";
import OurCultureSection from "../../sections/OurCultureSection";

const AboutPage = () => {
  const theme = useContext(ThemeContext);
  return (
    <AboutPageContainer>
      {(aboutData, servicesData, clientsData, cultureData, teamMembersData) => {
        const managementTeam = [];
        const employeeTeam = [];

        teamMembersData.map((member) =>
          member.memberType === MEMBER_TYPE.MANAGEMENT
            ? managementTeam.push(member)
            : employeeTeam.push(member)
        );

        const sortedEmployeeTeam =
          employeeTeam.length > 0 &&
          sortBy(employeeTeam, (employee) => employee.title);

        const pageName = "about";
        const cultureContent =
          cultureData &&
          cultureData.length > 0 &&
          cultureData.filter((f) => !f.hideCulture);
        const aboutContent = aboutData.find((data) => data.id === pageName);

        const servicesContent = servicesData.find(
          (data) => data.id === "services"
        );
        const clientsContent = clientsData.find(
          (data) => data.id === "clients"
        );

        const {
          keyCompetencies: aboutPageKeyCompetencies,
          pageId,
          pageBanner,
          introduction,
          ourTeam,
          ourApproach,
          ourClients,
        } = aboutContent;

        const reorderedEmployeesList = [...sortedEmployeeTeam];

        // Commented code that sorts employees based on displayOrder array

        // const employeeDisplayOrder =
        //   ourTeam?.displayOrder?.length > 0 &&
        //   ourTeam.displayOrder[0].employeeName &&
        //   ourTeam.displayOrder.map((item) => item.employeeName);
        // if (
        //   employeeDisplayOrder?.length > 0 &&
        //   sortedEmployeeTeam?.length > 0
        // ) {
        //   reorderedEmployeesList = sortBy(sortedEmployeeTeam, (item) =>
        //     employeeDisplayOrder.indexOf(item.title) !== -1
        //       ? employeeDisplayOrder.indexOf(item.title)
        //       : sortedEmployeeTeam.length
        //   );
        // }

        const { keyCompetencies: defaultKeyCompetencies, services } =
          servicesContent;
        const { textContent: defaultContent, clients } = clientsContent;

        const isKeyCompetencies =
          aboutPageKeyCompetencies || defaultKeyCompetencies;
        const showKeyCompetencies =
          isKeyCompetencies &&
          !aboutPageKeyCompetencies.hideSection &&
          services.length > 0;
        const KeyCompetenciesContent =
          showKeyCompetencies &&
          (isKeyCompetencies.textContent.heading ||
            isKeyCompetencies.textContent.content) &&
          isKeyCompetencies.textContent;

        const isOurClients = ourClients && !ourClients.hideSection;
        const clientContent =
          (isOurClients &&
            (ourClients.textContent.heading ||
              ourClients.textContent.content) &&
            ourClients.textContent) ||
          defaultContent;
        return (
          <>
            {pageBanner && !pageBanner.hideSection && (
              <PageBanner
                bannerContent={pageBanner.textContent}
                bgImage={pageBanner.backgroundImage}
              />
            )}
            {introduction && !introduction.hideSection && (
              <PageIntro
                data={introduction}
                imageSx={{
                  maxWidth: "100%",
                }}
                imageWrapperSx={{
                  mt: [0, null, null, "-20rem"],
                }}
                page={pageId}
              />
            )}
            {cultureContent &&
              cultureContent
                .filter((culture) => !culture.hideSection)
                .map((culture) => <OurCultureSection data={culture.summary} />)}

            {ourTeam && !ourTeam.hideSection && (
              <Section
                sx={{
                  ":before": {
                    bg: "grays.0",
                    content: "''",
                    display: ["none", null, null, "block"],
                    height: "50rem",
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: -1,
                  },
                  bg: "white",
                  position: "relative",
                }}
              >
                {ourTeam.textContent && (
                  <MarkdownToHtml
                    heading={ourTeam.textContent.heading}
                    source={ourTeam.textContent.content}
                    sx={{ textAlign: "center" }}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: ["center", null, null, "space-around"],
                    mx: [0, null, null, 5],
                  }}
                >
                  {managementTeam &&
                    managementTeam.map(
                      (member) =>
                        !member.hideMember && (
                          <ImageCard
                            data={member}
                            wrapperSx={{
                              width: [
                                "100%",
                                null,
                                "calc(100%/2)",
                                "calc(100%/3)",
                              ],
                            }}
                          />
                        )
                    )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {!ourTeam?.hideEmployees &&
                    reorderedEmployeesList &&
                    reorderedEmployeesList.map(
                      (member) =>
                        !member.hideMember && (
                          <ImageCard
                            data={member}
                            hideJobTitle={ourTeam?.hideDesignation}
                            imageSx={{ bg: "grays.0" }}
                            wrapperSx={{
                              width: [
                                "100%",
                                null,
                                "calc(100%/2)",
                                "calc(100%/4)",
                              ],
                            }}
                          />
                        )
                    )}
                </Box>
              </Section>
            )}
            {ourApproach && !ourApproach.hideSection && (
              <PointsColumns data={ourApproach} />
            )}
            {showKeyCompetencies && (
              <CardsGrid
                containerSx={{
                  mb: [5, null, null, `calc(${theme.space[6]} * -2)`],
                }}
                contentWrapperSx={{ mb: 0 }}
                data={{ services, textContent: KeyCompetenciesContent }}
                sectionSx={{ pb: 0, zIndex: 2 }}
              />
            )}
            {isOurClients && (
              <Testimonials
                containerSx={{
                  pt: [null, null, null, `calc(${theme.space[6]} * 3)`],
                }}
                data={{
                  clients,
                  textContent: clientContent,
                }}
              />
            )}
          </>
        );
      }}
    </AboutPageContainer>
  );
};

AboutPage.propTypes = {};

export default AboutPage;
